import { createSlice } from "@reduxjs/toolkit";
import { accomodationList, accomomdationProfile, listRoutes, routeProfile } from "../Action/AdminActions";
import { toast } from "react-toastify";

export const accomodationSlice = createSlice({
  name: "accomodationSlice",
  initialState: {
    listing: "",
    databyid:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(accomodationList.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.listing = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(accomomdationProfile.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.databyid = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    
  },
});

export default accomodationSlice.reducer;
