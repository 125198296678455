import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Pagination";
import { deletetosee, toseeList } from "../Redux/Action/AdminActions";

export default function ToSee() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const data = useSelector((state) => state.toseedata.listing);
  console.log(data, "list");
  const [show, setShow] = useState(false);
  const [pages, setpages] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [id, setId] = useState("");
  const [flag, setflag] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      toseeList({ page: search ? 1 : pages, limit: limit, search: search })
    );
  }, [flag, pages, search, limit]);

  const handleClose = () => setShow(false);

  const handleroutes = (id) => {};
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>To See</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Control
                  onChange={(e) => setsearch(e.target.value)}
                  type="search"
                  placeholder="Search.."
                />
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  {/* <Link to="/CreateToSee">Create</Link> */}
                  <button
                    onClick={() => {
                      navigate("/CreateToSee", {
                        state: {
                          name: "add",
                        },
                      });
                    }}
                  >
                    Create
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.data?.toSee?.length + (pages - 1) * limit} of{" "}
                {data?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Route / Place</th>
                <th>Main Image</th>
                <th>Cover Image</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.toSee?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;

                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{x?.title}</td>
                    <td>
                      <img src={`${BASE_URL}${x?.main_image}`} />
                    </td>

                    <td>
                      {x?.cover_image?.map((y) => {
                        return <img src={`${BASE_URL}${y}`} />;
                      })}
                    </td>
                    <td>
                      {x?.type == 0
                        ? "All"
                        : x?.type == 1
                        ? "Parking"
                        : x?.type == 2
                        ? "Bus Stop"
                        : x?.type == 3
                        ? "Train Stop"
                        : x?.type == 4
                        ? "Wifi Points"
                        : x?.type == 5
                        ? "Camping Area"
                        : x?.type == 6
                        ? "Picnic Area"
                        : x?.type == 7
                        ? "Panoramic Point"
                        : "Information Points"}
                    </td>
                    {/* <td>Easy</td>
                <td>Cycle , Walk</td> */}
                    <td className="action-btns">
                      <button
                        type="button"
                        onClick={() => {
                          setId(x?._id);
                          setShow(true);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          navigate(`/EditToSee/${x?._id}`, {
                            state: {
                              name: "edit",
                              id: x?._id,
                            },
                          });
                        }}
                      >
                        Edit
                      </button>
                      {/* <Link to="/RoutesView"> */}

                      {/* </Link> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.toSee?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.totalPages}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />
      <Modal show={show} onHide={handleClose} className="delete-popup" centered>
        <Modal.Body>
          Are You Sure You Want To <br />
          Delete This{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(deletetosee({ toSeeId: id })).then((res) => {
                if (res) {
                  setflag(!flag);
                  setShow(false);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
