import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Editor from "../Components/Layout/TextEditor";
import MyEditor from "../Components/Layout/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  createroutes,
  editroutes,
  routeProfile,
} from "../Redux/Action/AdminActions";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../Components/Layout/TextEditor";
import Multiselect from "multiselect-react-dropdown";
import ItalianTextAditor from "../Components/Layout/ItalianTextEditor";
import JourneyTextEditor from "../Components/Layout/journeyTextEditor";

export default function RoutesView() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [countryCode, setCountryCode] = useState([
    {
      country_code: "",
    },
  ]);
  const [phoneNumber, setphoneNumber] = useState([
    {
      phone_number: "",
    },
  ]);

  // const location = useLocation();
  // console.log(location)
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [imageserr, setImageserr] = useState("");
  const [img, setImg] = useState();
  const [htmlContent, setHtmlContent] = useState();
  const [htmlContent2, setHtmlContent2] = useState();
  const [italianhtmlContent, setitalianHtmlContent] = useState();
  const [italianhtmlContent2, setitalianHtmlContent2] = useState();
  const [title, setTitle] = useState("");
  const [italianTile,setItalianTitle] = useState("")
  const [type, setType] = useState("");
  const [prev, setPrev] = useState(null);
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [htmlContenterr2, setHtmlContenterr2] = useState();
  const [italianhtmlContenterr, setitalianHtmlContenterr] = useState();
  const [titleerr, setTitleerr] = useState("");
  const [typeerr, setTypeerr] = useState("");
  const [preverr, setPreverr] = useState(null);
  const [htmlEntity, setHtmlEntity] = useState("");
  const [htmlEntity2, setHtmlEntity2] = useState("");
  const [italianhtmlEntity, setItalianHtmlEntity] = useState("");
  const [italianhtmlEntity2, setItalianHtmlEntity2] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  // const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [website, setwebsite] = useState("");
  // const [countryCodeerr, setCountryCodeerr] = useState("");
  const [phoneNumbererr, setphoneNumbererr] = useState("");
  const [emailerr, setemailerr] = useState("");
  const [websiteerr, setwebsiteerr] = useState("");

  const dataHtmlHandle = (html) => {
    setHtmlEntity(html);
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlContenterr("");
  };
  const dataHtmlHandle2 = (html) => {
    setHtmlEntity2(html);
    setHtmlContent2(draftToHtml(JSON.parse(html)));
    setHtmlContenterr2("");
  };

  const italiandataHtmlHandle = (html) => {
    console.log(html, "itlaisnhtml");

    setItalianHtmlEntity(html);
    setitalianHtmlContent(draftToHtml(JSON.parse(html)));
    // setHtmlContenterr("");
  };
  const italiandataHtmlHandle2 = (html) => {
    console.log(html, "itlaisnhtml");

    setItalianHtmlEntity2(html);
    setitalianHtmlContent2(draftToHtml(JSON.parse(html)));
    // setHtmlContenterr("");
  };

  const [options, setOptions] = useState([
    { name: "By Walking", id: 0 },
    { name: "By Bicycle", id: 1 },
  ]);
  const [selectedValue, setSelectedValue] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
    setTypeerr("");
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;

    const selectedImages = [];

    Array.from(files).forEach((file) => {
      if (selectedImages.length < 3 && file.type.startsWith("image/")) {
        selectedImages.push(file);
      }
    });

    setImages(selectedImages);
    setImageserr("");
  };

  const handleaddcodeclone = () => {
    setCountryCode([...countryCode, { country_code: "" }]);
  };
  const handleaddphoneclone = () => {
    setphoneNumber([...phoneNumber, { phone_number: "" }]);
  };

  const handlecountrycodechange = (e, i) => {
    const onChangeVal = [...countryCode];
    onChangeVal[i][e.target.name] = e.target.value;
    setCountryCode(onChangeVal);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };
  const handlephonechange = (e, i) => {
    const onChangeVal = [...phoneNumber];
    onChangeVal[i][e.target.name] = e.target.value;
    setphoneNumber(onChangeVal);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handlesave = () => {
    let isvalid = true;

    if (!htmlContent) {
      if (italianhtmlContent) {
        setHtmlContenterr("");
      } else {
        setHtmlContenterr("Required*");
        isvalid = false;
      }
    }
    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }
    if (images.length == 0) {
      setImageserr("Required*");
      isvalid = false;
    }
    if (selectedValue?.length == 0) {
      setTypeerr("Required*");
      isvalid = false;
    }

    // if (!countryCode) {
    //   setCountryCodeerr("Required*");
    //   isvalid = false;
    // }
    // if (!phoneNumber) {
    //   setphoneNumbererr("Required*");
    //   isvalid = false;
    // }
    // if (!email) {
    //   setemailerr("Required*");
    //   isvalid = false;
    // }
    // if (!website) {
    //   setwebsiteerr("Required*");
    //   isvalid = false;
    // }
    if (!prev) {
      setPreverr("Required*");
      isvalid = false;
    }

    if (isvalid) {
      const formdata = new FormData();
      
      
      countryCode.forEach((code, index) => {
        console.log(code?.countryCode === undefined, "code");
        if (code?.countryCode === undefined) {
          // formdata.append(`country_code[${index}]`, "");
        } else {
          formdata.append(`country_code[${index}]`, code.countryCode);
        }
      });
    
      phoneNumber.forEach((phoneNum, index) => {
        console.log(phoneNum?.phoneNumber, "phonenumber");
        if (phoneNum?.phoneNumber === undefined) {
          // formdata.append(`phone_number[${index}]`, "");

        } else {
          formdata.append(`phone_number[${index}]`, phoneNum.phoneNumber);
        }
      });


      formdata.append("description", htmlContent);
      formdata.append("description_2", htmlContent2);
      formdata.append("italian_description", italianhtmlContent);
      formdata.append("italian_description_2", italianhtmlContent2);
      formdata.append("title", title);
      formdata.append("italian_title",italianTile)
      // formdata.append("coverImage", img);
      selectedValue.forEach((option) => {
        formdata.append("type", option?.id);
      });
      // formdata.append("type", selectedValue);

      formdata.append("html_entity_content", htmlEntity);
      formdata.append("html_entity_content_2", htmlEntity2);
      formdata.append("italian_html_entity_content", italianhtmlEntity);
      formdata.append("italian_html_entity_content_2", italianhtmlEntity2);
      formdata.append("mainImage", img);
      if (website) {
        formdata.append("website", website);
      } else {
        formdata.append("website", "");
      }
      if (email) {
        formdata.append("email", email);
      } else {
        formdata.append("email", "");
      }
      // if (countryCode) {
      //   formdata.append("country_code", countryCode);
      // } else {
      //   formdata.append("country_code", 0);
      // }
      // if (phoneNumber) {
      //   formdata.append("phone_number", phoneNumber);
      // } else {
      //   formdata.append("phone_number", "");
      // }

      for (let i = 0; i < images.length; i++) {
        console.log(images[i], "loop");

        formdata.append("coverImage", images[i]);
      }

      dispatch(createroutes(formdata)).then((res) => {
        if (res?.payload?.success) {
          navigate("/Routes");
        }
      });
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Create Routes</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Routes">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-5">
            <div className="customer-form-new">
              <div className="customer-form-inner m-0">
                <Row>
                  <Col lg={12}>
                    <h2 className="main-head">English</h2>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            setTitleerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {titleerr}
                        </Form.Label>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Title (Italian):</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          onChange={(e) => {
                            setItalianTitle(e.target.value)
                            // setTitleerr("")
                            }}
                          type="text"
                          placeholder="Type......."
                        />
                        {/* <Form.Label className="text-danger">
                          {titleerr}
                        </Form.Label> */}
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Routes Type:</Form.Label>
                        {/* 
                        <ng-multiselect-dropdown
                          placeholder="'custom placeholder'"
                          settings="dropdownSettings"
                          data="dropdownList"
                          ngModel="selectedItems"
                          onSelect="onItemSelect($event)"
                          onSelectAll="onSelectAll($event)"
                        ></ng-multiselect-dropdown> */}

                        <Multiselect
                          options={options}
                          selectedValues={selectedValue}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue="name"
                        />

                        {/* <Form.Select
                          name="type"
                          // value={createRoute.type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setTypeerr("");
                          }}
                          aria-label="Default select example"
                        >
                          <option>Select</option>
                          <option value="0">By Walking</option>
                          <option value="1">By Bicycle</option>
                          
                        </Form.Select> */}
                        <Form.Label className="text-danger">
                          {typeerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="admin-box-select">
                        <Form.Group className="mb-3 w-100">
                          <Form.Label>Country Code:</Form.Label>
                          {/* <Form.Control
                          // value={createRoute.title}   
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                            setCountryCodeerr("");
                            // setTitleerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        /> */}

                          {countryCode?.map((cat, i) => {
                            return (
                              <div className="w-100">
                                <Form.Group className="mb-3 width-100 duplicate-field">
                                  {/* <Form.Label>Country Code:</Form.Label> */}
                                  <Form.Control
                                    name="countryCode"
                                    onChange={(e) =>
                                      handlecountrycodechange(e, i)
                                    }
                                    value={countryCode.country_code}
                                    type="text"
                                    placeholder="Type "
                                  />
                                  <Form.Label className="text-danger">
                                    {/* {countryCodeerr} */}
                                  </Form.Label>
                                </Form.Group>
                              </div>
                            );
                          })}

                          {/* <Form.Label className="text-danger">
                          {countryCodeerr}
                        </Form.Label> */}
                        </Form.Group>
                      </div>
                      <div
                        onClick={handleaddcodeclone}
                        className="add-cate-icon"
                      >
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 1V19"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 10H19"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="admin-box-select">
                        <Form.Group className="mb-3 ">
                          <Form.Label>Phone Number:</Form.Label>
                          {/* <Form.Control
                          // value={createRoute.title}
                          onChange={(e) => {
                            setphoneNumber(e.target.value);
                            setphoneNumbererr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        /> */}
                          {/* <Form.Label className="text-danger">
                          {phoneNumbererr}
                        </Form.Label> */}

                          {phoneNumber?.map((cat, i) => {
                            return (
                              <>
                                <Form.Group className="mb-3 duplicate-field width-100">
                                  {/* <Form.Label>Country Code:</Form.Label> */}
                                  <Form.Control
                                    name="phoneNumber"
                                    onChange={(e) => handlephonechange(e, i)}
                                    value={phoneNumber.phone_number}
                                    type="text"
                                    placeholder="Type "
                                  />
                                  <Form.Label className="text-danger">
                                    {/* {countryCodeerr} */}
                                  </Form.Label>
                                </Form.Group>
                              </>
                            );
                          })}
                        </Form.Group>
                      </div>
                      <div
                        onClick={handleaddphoneclone}
                        className="add-cate-icon"
                      >
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 1V19"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 10H19"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                          // value={createRoute.title}
                          onChange={(e) => {
                            setemail(e.target.value);
                            setemailerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {emailerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Website:</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          onChange={(e) => {
                            setwebsite(e.target.value);
                            setwebsiteerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {websiteerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Cover Image</Form.Label>
                      <div className="upload-cover-image">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageChange}
                        />
                        <p>Upload Cover Image</p>
                        <div className="create-upload-images">
                          {" "}
                          {images.map((image, index) => {
                            console.log(image, "ijiin");
                            return (
                              <div key={index}>
                                <img
                                  src={
                                    typeof image === "string"
                                      ? `${BASE_URL}${image}`
                                      : URL.createObjectURL(image)
                                  }
                                  alt={`Image ${index + 1}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <Form.Label className="text-danger">
                        {imageserr}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Main Image</Form.Label>
                      <div className="upload-cover-image">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            setImg(e.target.files[0]);
                            setPrev(URL.createObjectURL(e.target.files[0]));
                            setPreverr("");
                          }}
                        />
                        <p>Upload Main Image</p>
                        <div className="create-upload-images">
                          <img src={prev} />
                        </div>
                      </div>
                      <Form.Label className="text-danger">{preverr}</Form.Label>
                    </Form.Group>
                  </Col>

                  <Col lg={12} className="mt-4">
                    <Form.Label>Description (English)</Form.Label>
                    <div className="rich-text-edit-main">
                      <TextEditor htmltext={dataHtmlHandle} />
                    </div>
                    <Form.Label className="text-danger">
                      {htmlContenterr}
                    </Form.Label>
                  </Col>



                  <Col lg={12} className="mt-4">
                    <Form.Label>Start Journey Description (English)</Form.Label>
                    <div className="rich-text-edit-main">
                      <JourneyTextEditor htmltext={dataHtmlHandle2} />
                    </div>
                    <Form.Label className="text-danger">
                      {htmlContenterr2}
                    </Form.Label>
                  </Col>




                  <Col lg={12} className="mt-4">
                    <Form.Label>Description (Italian)</Form.Label>
                    <div className="rich-text-edit-main">
                      <ItalianTextAditor htmltext={italiandataHtmlHandle} />
                    </div>
                    <Form.Label className="text-danger">
                      {/* {htmlContenterr} */}
                    </Form.Label>
                  </Col>





                  <Col lg={12} className="mt-4">
                    <Form.Label>Start Journey Description (Italian)</Form.Label>
                    <div className="rich-text-edit-main">
                      <ItalianTextAditor htmltext={italiandataHtmlHandle2} />
                    </div>
                    <Form.Label className="text-danger">
                      {/* {htmlContenterr} */}
                    </Form.Label>
                  </Col>
                 

                  <Col lg={2} className="mx-auto">
                    <div
                      onClick={handlesave}
                      className="cmn-btn d-flex justify-content-center mt-4"
                    >
                      {/* <Link to="#" className="common-colr-btn"> */}
                      <button className=" common-colr-btn"> SAVE</button>
                      {/* </Link> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
