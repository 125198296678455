import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { otpverification } from "../Redux/Action/AdminActions";

export default function LoginOtp() {
  const location = useLocation();
  console.log(location)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTPverification, setOTPverification] = useState({
    otp: "",
    email: location?.state?.email,
  });
  const [error, setError] = useState(null);

  const handlechange = (e) => {
    setOTPverification((old) => ({ ...old, otp: e.target.value }));
    setError("");
  };

  const handleclick = async () => {
    let isvalid = true;
    if (!OTPverification?.otp) {
      setError("Please enter OTP");
      isvalid = false;
    }
    if (isvalid) {
      dispatch(otpverification(OTPverification)).then((res)=>{
        console.log(res)
        if(res?.payload?.success){
          navigate("/change-password",{state:{
            email:OTPverification.email
          }})
        }
      });
    }
  };

  console.log(location);
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter OTP</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter OTP</Form.Label>
                      <Form.Control
                        name="otp"
                        value={OTPverification?.otp}
                        onChange={handlechange}
                        type="password"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">{error}</Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    // to="/"
                    onClick={handleclick}
                    style={{ cursor: "pointer" }}
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
