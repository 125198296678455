import axios from "axios";

export const AdminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    Authorization: window.sessionStorage.getItem("token"),
  },
});

AdminApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
    //   setTimeout(() => {
        sessionStorage.clear();
        window.location.reload(false);
        window.location.href = "/";
    //   }, 1000);
    }
    return error.response;
  }
);

export default AdminApi;
