import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminApi from "../../services/AdminApi";
import { toast } from "react-toastify";


export const login = createAsyncThunk("login",async(details)=>{
    const {data} = await AdminApi.post("/auth/login",details)
    console.log(data)
    if(data?.success){
        toast.success(data?.message)
        sessionStorage.setItem("token",data?.data?.token)
        window.location.href="/Dashboard"
    }else{
        toast.error(data?.message)
    }
    return data

})