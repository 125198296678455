import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { accomodationList, deleteAccomodation } from "../Redux/Action/AdminActions";
import Pagination from "../Components/Pagination";


export default function Accommodation() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  
  const data = useSelector((state) => state.accomodationData.listing);
  console.log(data, "list");
  const [show, setShow] = useState(false);
  const [pages, setpages] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [id, setId] = useState("");
  const [flag, setflag] = useState(true);
  
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      accomodationList({
        page: search ? 1 : pages,
        limit: limit,
        search: search,
      })
    );
  }, [flag, pages, search, limit]);

  const handleClose = () => setShow(false);

  console.log(id)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Accommodation</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={2} xl={3} lg={4} md={4}>
            
                  <Form.Control
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <button
                    onClick={() => {
                      navigate("/CreateAccommodation", {
                        state: {
                          // name: "add",
                        },
                      });
                    }}
                  >
                    Create
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.data?.Accommodation?.length + (pages - 1) * limit} of{" "}
                {data?.data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Accommodation name </th>
                <th>Main Image</th>
                <th>Cover Image</th>
                <th>Accommodation type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.Accommodation?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{x?.title}</td>
                    <td><img src={`${BASE_URL}${x?.main_image}`} /></td>
                    <td>
                      {/* <img src={`${BASE_URL}${x?.cover_image}`} /> */}
                      {x?.cover_image?.map((y)=>{
                        
                      
                      return( <img src={`${BASE_URL}${y}`} />)
                        
                      })}
                    </td>
                    <td>
                      {x?.type == 0
                        ? "Hotel"
                        : x?.type == 1
                        ? "Hostel"
                        : x?.type == 2
                        ? " Guest House"
                        : "Pilgram Hostel"}
                    </td>
                    <td className="action-btns">
                      <button
                        type="button"
                        onClick={() => {
                          setId(x?._id);
                          setShow(true);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          navigate(`/EditAccommodation/${x?._id}`, {
                            state: {
                              // name: "edit",
                              // id: x?._id,
                            },
                          });
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.Accommodation?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.totalPages}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />
      <Modal show={show} onHide={handleClose} className="delete-popup" centered>
        <Modal.Body>
          Are You Sure You Want To <br />
          Delete This Accomodation
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(deleteAccomodation({ accommodationId: id })).then((res) => {
                if (res) {
                  setflag(!flag);
                  setShow(false);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
