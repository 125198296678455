import { createSlice } from "@reduxjs/toolkit";
import { listRoutes, listRoutesforDropdown, routeProfile } from "../Action/AdminActions";
import { toast } from "react-toastify";

export const routeSlice = createSlice({
  name: "routeSlice",
  initialState: {
    listing: "",
    databyid:"",
    listroutes:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listRoutes.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.listing = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(routeProfile.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.databyid = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(listRoutesforDropdown.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.listroutes = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
  },
});

export default routeSlice.reducer;
