import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function CreateAdmin() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Administrator</h2>
            {/* <p>ADMIN</p> */}
          </div>
          {/* <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div> */}
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Administrators">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="cmn-btn">
                <button type="button">Create</button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="customer-form-new mt-4">
              <h2>BASIC DETAILS</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Admin Name</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control type="email" placeholder="Type Here" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Mobile Phone</Form.Label>
                      <Form.Control type="number" placeholder="+44" />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Role</Form.Label>
                      <Form.Control type="text" placeholder="Type Here" />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          {/* </Col>
          <Col lg={8} className="mt-4 mb-4"> */}
            <div className="customer-form-new mt-4 mb-4">
              <h2>ACCESS & PERMISSIONS</h2>
              <hr className="cmn-border" />
              <div className="access-permission-main">
                <Row>
                  <Col lg={12}>
                    <div className="access-select-box mt-4 pt-2">
                      <h3>GENERAL</h3>
                      <div className="d-flex inner">
                        <h3>YES</h3>
                        <h3>No</h3>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>View User Sensitive Data</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Edit Data</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Dashboard</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Customer Management</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Vendor Management</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Business Management</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Verifications</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Support</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box">
                      <p>Notification</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="access-select-box mb-4">
                      <p>Manage Administration</p>
                      <div className="d-flex inner">
                        <Form>
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <div className="user-profile-main px-3 mt-4 py-4 h-100">
                  <div className="property-main mt-0">
                    <h2 className="property">Activity</h2>
                    <div className="active-default-links">
                      <ul>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                        <li>
                          <h6>[Admin Name] lorem ipsum is dummy text. <span><Link to="#">Support Ticket</Link></span></h6>
                          <p>01/01/2023 13:32</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
