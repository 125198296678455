import { createAsyncThunk } from "@reduxjs/toolkit";
import AdminApi from "../../services/AdminApi";
import { toast } from "react-toastify";

export const logout = createAsyncThunk("logout", async () => {
  const { data } = await AdminApi.post("/auth/logout");
  if (data?.success) {
    // window.location.href="/"
  } else {
    toast.error(data?.message);
  }
  return data;
});

// forget password

export const forgetAdminPassword = createAsyncThunk(
  "forgetAdminPassword",
  async (details) => {
    const { data } = await AdminApi.post("/auth/forgetPassword", details);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

export const otpverification = createAsyncThunk(
  "otpverification",
  async (details) => {
    const { data } = await AdminApi.post("/auth/verifyotp", details);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

export const resetAdminPassword = createAsyncThunk(
  "resetAdminPassword",
  async (details) => {
    const { data } = await AdminApi.post("/auth/resetPassword", details);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

//   change password

export const ChangeAdminPassword = createAsyncThunk(
  "ChangePassword",
  async (details) => {
    const { data } = await AdminApi.post("/auth/changePassword", details);

    // if (data?.success) {
    //   toast.success(data?.message);
    // } else {
    //   toast.error(data?.message);
    // }

    return data;
  }
);

// route create

export const createroutes = createAsyncThunk(
  "createRoutes",
  async (details) => {
    const { data } = await AdminApi.post("/createRoutes", details);

    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }

    return data;
  }
);

// route listing

export const listRoutes = createAsyncThunk("listRoutes", async (details) => {
  const { data } = await AdminApi.get(
    `/routeList?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
  );
  return data;
});

// list routes for dropdown
export const listRoutesforDropdown = createAsyncThunk("listRoutesforDropdown", async (details) => {
  const { data } = await AdminApi.get(
    `/routeList`
  );
  return data;
});


// delete route

export const deleteRoute = createAsyncThunk("deleteRoute", async (detail) => {
  const { data } = await AdminApi.post(`/deleteRoutes`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
  return data;
});

// edit routes

export const editroutes = createAsyncThunk("editroutes", async (details) => {
  const { data } = await AdminApi.put("/editRoute", details);

  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }

  return data;
});

// get route by id

export const routeProfile = createAsyncThunk(
  "routeProfile",
  async (details) => {
    const { data } = await AdminApi.get(`/getRoute?routeId=${details}`);
    return data;
  }
);

// dashboard

export const dashboard = createAsyncThunk("dashboard", async (details) => {
  const { data } = await AdminApi.get(`/dashboard`);
  return data;
});

// get accomodation

export const accomodationList = createAsyncThunk(
  "accomodationList",
  async (details) => {
    const { data } = await AdminApi.get(
      `/accommodationList?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
    );
    return data;
  }
);


//  create accomodation

export const createaccomodations = createAsyncThunk(
  "createaccomodations",
  async (details) => {
    const { data } = await AdminApi.post("/createAccomodation", details);

    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }

    return data;
  }
);

// get accomodation bu id

export const accomomdationProfile = createAsyncThunk(
  "accomomdationProfile",
  async (details) => {
    const { data } = await AdminApi.get(`/getAccommodation?accommodationId=${details}`);
    return data;
  }
);

// delete accomodation

export const deleteAccomodation = createAsyncThunk("deleteAccomodation", async (detail) => {
  const { data } = await AdminApi.post(`/deleteAccommodation`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
  return data;
});

// edit accomodation

export const editaccomodation = createAsyncThunk("editaccommodation", async (details) => {
  const { data } = await AdminApi.put("/editAccommodation", details);

  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }

  return data;
});

// get tosee

export const toseeList = createAsyncThunk(
  "toseeList",
  async (details) => {
    const { data } = await AdminApi.get(
      `/toSeeList?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
    );
    return data;
  }
);

//  create tosee

export const createtosee = createAsyncThunk(
  "createtosee",
  async (details) => {
    const { data } = await AdminApi.post("/createToSee", details);

    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }

    return data;
  }
);

// get tosee bu id

export const toseeprofile = createAsyncThunk(
  "toseeprofile",
  async (details) => {
    const { data } = await AdminApi.get(`/getToSeeData?toSeeId=${details}`);
    return data;
  }
);

// delete tosee

export const deletetosee = createAsyncThunk("deletetosee", async (detail) => {
  const { data } = await AdminApi.post(`/deleteToSee`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
  return data;
});


// edit accomodation

export const edittosee = createAsyncThunk("edittosee", async (details) => {
  const { data } = await AdminApi.put("/editToSee", details);

  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }

  return data;
});


