import { configureStore } from "@reduxjs/toolkit";
import { routeSlice } from "./Reducer/RoutesSlice";
import { dashboardSlice } from "./Reducer/DashboardSlice";
import {accomodationSlice} from "./Reducer/accomodationSlice";
import {toseeSlice} from "./Reducer/toseeSlice";

export const store = configureStore({
  reducer: {
    routesdata: routeSlice.reducer,
    dashdata: dashboardSlice.reducer,
    accomodationData: accomodationSlice.reducer,
    toseedata:toseeSlice.reducer
  },
});
