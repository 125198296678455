import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import CreateAdmin from "./pages/CreateAdmin";
import AccountActivation from "./pages/AccountActivation";
import AddServices from "./pages/AddServices";
import RoutesPage from "./pages/RoutesPage";
import ChangePassword from "./pages/ChangePassword";
import RoutesView from "./pages/RoutesView";
import Accommodation from "./pages/Accommodation";
import Blogs from "./pages/ToSee";
import CreateAccommodation from "./pages/CreateAccommodation";
import ToSee from "./pages/ToSee";
import CreateToSee from "./pages/CreateToSee";
import ForgotPasswordTwo from "./pages/ForgotPasswordtwo";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import EditAccommodation from "./pages/EditAccomodation";
import EditRoutesView from "./pages/EditRouteView";
import EditToSee from "./pages/Edittosee";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/RoutesView" element={<RoutesView />} />
            <Route path="/EditRouteView/:id" element={<EditRoutesView />} />
            <Route path="/Accommodation" element={<Accommodation />} />

            <Route path="/Otp" element={<LoginOtp />} />
            <Route path="/Forgot-Password" element={<ForgotPassword />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/CreateAdmin" element={<CreateAdmin />} />
            <Route path="/AccountActivation" element={<AccountActivation />} />
            <Route path="/AddServices" element={<AddServices />} />
            <Route path="/Routes" element={<RoutesPage />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route
              path="/CreateAccommodation"
              element={<CreateAccommodation />}
            />
            <Route
              path="/EditAccommodation/:id"
              element={<EditAccommodation />}
            />
            <Route path="/ToSee" element={<ToSee />} />
            <Route path="/CreateToSee" element={<CreateToSee />} />
            <Route path="/EditToSee/:id" element={<EditToSee />} />
            <Route path="/change-password" element={<ForgotPasswordTwo />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
