import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { useState } from "react";

const ItalianEditTextAditor = ({ htmlHandle, givenContent, webData }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  console.log(webData,"webdata")

  // Convert HTML content to EditorState when the givenContent prop changes
  // useEffect(() => {
  //   if (givenContent) {
  //     const blocksFromHTML = convertFromHTML(givenContent);
  //     const contentState = ContentState.createFromBlockArray(blocksFromHTML);
  //     const newEditorState = EditorState.createWithContent(contentState);
  //     setEditorState(newEditorState);
  //   }
  // }, [givenContent]);
  useEffect(() => {
    

    // if (givenContent) {
    //   const contentState = webData == undefined ? null : convertFromRaw(JSON.parse(webData));
    //   setEditorState(EditorState.createWithContent(contentState));
    // }


    if (givenContent) {
      try {
        if (webData) {
          const contentState = convertFromRaw(JSON.parse(webData));
          setEditorState(EditorState.createWithContent(contentState));
        } else {
          const blocksFromHTML = convertFromHTML(givenContent);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML);
          
          const newEditorState = EditorState.createWithContent(contentState );
         
          setEditorState(newEditorState);
        }
      } catch (error) {
        console.error("Error parsing webData:", error);
        const blocksFromHTML = convertFromHTML(givenContent);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [givenContent, webData]);

  console.log(editorState,"editorState")

  const fontFamilyStyleMap = {
    // Arial: {
    //   fontFamily: "Arial, sans-serif",
    // },
    // "Times New Roman": {
    //   fontFamily: "Times New Roman, serif",
    // },
    // Add more font families as needed
    Poppins: {
      fontFamily: "Poppins, sans-serif",
    },
  };

  const onEditorStateChange = (newEditorState) => {
    //     setEditorState(newEditorState);
    // let blogContent = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))

    //     const editorStateInHtml = draftToHtml(
    //       convertToRaw(newEditorState.getCurrentContent())
    //     );

    // setDescription({
    //   htmlValue: editorStateInHtml,
    //   editorState: blogContent,
    // });

    // const editorStateInHtml = draftToHtml(
    //   convertToRaw(newEditorState.getCurrentContent())
    // );

    htmlHandle(
      JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
    );

    setEditorState(newEditorState);
    // // htmlHandle(editorStateInHtml);
  };

  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Description..."
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          inline: {
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "superscript",
              "subscript",
            ],
            bold: { className: "bordered-option-classname" },
            italic: { className: "bordered-option-classname" },
            underline: { className: "bordered-option-classname" },
            strikethrough: { className: "bordered-option-classname" },
            superscript: { className: "bordered-option-classname" },
            subscript: { className: "bordered-option-classname" },
          },
          colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              "#000000",
              "#333333",
              "#666666",
              "#999999",
              "#cccccc",
              "#ffffff",
              "#ff0000",
              "#00ff00",
              "#0000ff",
              "#164833",
              "#65966B"
            ],
          },

          fontFamily: {
            options: ["Poppins"],
            title: "Font Family",
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        customStyleMap={{
          ...fontFamilyStyleMap,
        }}
      />
    </div>
  );
};

export default ItalianEditTextAditor;
