import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import { useState } from "react";
const RTextAditor = ({ htmltext }) => {
  const [description, setDescription] = useState({
    htmlValue: "<p></p>\n",
    editorState: EditorState.createEmpty(),
  });
  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );
    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });
    htmltext(JSON.stringify(convertToRaw(editorValue.getCurrentContent())));
  };


 
  
  const fontFamilyStyleMap = {
  
    Poppins: {
      fontFamily: "Poppins, sans-serif",
    },
  };

  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Description..."
        
        
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          inline: {
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "superscript",
              "subscript",
            ],
            bold: { className: "bordered-option-classname" },
            italic: { className: "bordered-option-classname" },
            underline: { className: "bordered-option-classname" },
            strikethrough: { className: "bordered-option-classname" },
            superscript: { className: "bordered-option-classname" },
            subscript: { className: "bordered-option-classname" },
          },
          colorPicker: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              "#000000",
              "#333333",
              "#666666",
              "#999999",
              "#cccccc",
              "#ffffff",
              "#ff0000",
              "#00ff00",
              "#0000ff",
              "#164833",
              "#65966B"
            ],
          },

          fontFamily: {
           options: ["Poppins"],
            title: "Font Family",
          },

          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        customStyleMap={{
          ...fontFamilyStyleMap,
        }}
      />
    </div>
  );
};

export default RTextAditor;
