import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Editor from "../Components/Layout/TextEditor";
import MyEditor from "../Components/Layout/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  createroutes,
  editroutes,
  edittosee,
  listRoutesforDropdown,
  routeProfile,
  toseeprofile,
} from "../Redux/Action/AdminActions";
import draftToHtml from "draftjs-to-html";
import EditTextAditor from "../Components/Layout/EditTextEsitor";
import ItalianEditTextAditor from "../Components/Layout/ItalianEditTextEditor";

export default function EditTosee() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [countryCode, setCountryCode] = useState([
    {
      country_code: "",
    },
  ]);

  const [phoneNumber, setphoneNumber] = useState([
    {
      phone_number: "",
    },
  ]);

  const data = useSelector((state) => state.routesdata.listroutes);
  console.log(data, "list");

  let { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const [img, setImg] = useState();
  const [routeType, setRoutetype] = useState("");
  const [images, setImages] = useState([]);
  const [imageserr, setImageserr] = useState("");
  const [htmlContent, setHtmlContent] = useState();
  const [italianhtmlContent, setitalianHtmlContent] = useState();
  const [title, setTitle] = useState("");
  const [italianTile,setItalianTitle] = useState("")
  const [type, setType] = useState("");
  const [prev, setPrev] = useState(null);
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [titleerr, setTitleerr] = useState("");
  const [typeerr, setTypeerr] = useState("");
  const [preverr, setPreverr] = useState(null);
  const [givenContent, setGivenContent] = useState();
  const [italiangivenContent, setitalianGivenContent] = useState();
  const [webdata, setwebdata] = useState();
  const [italianwebdata, setitalianwebdata] = useState();
  // const [countryCode, setCountryCode] = useState("");
  // const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [website, setwebsite] = useState("");
  const [countryCodeerr, setCountryCodeerr] = useState("");
  const [phoneNumbererr, setphoneNumbererr] = useState("");
  const [emailerr, setemailerr] = useState("");
  const [websiteerr, setwebsiteerr] = useState("");
  const [routeTypeerr, setRoutetypeerr] = useState("");

  const viewRoute = useSelector((state) => state?.toseedata?.databyid);

  const [htmlEntity, setHtmlEntity] = useState("");
  const [italianhtmlEntity, setitalianHtmlEntity] = useState("");
  useEffect(() => {
    dispatch(toseeprofile(id));

    dispatch(listRoutesforDropdown());
  }, []);

  const dataHtmlHandle = (html) => {
    console.log(
      html,
      draftToHtml(JSON.parse(html)),
      "---------html-----------------"
    );
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlEntity(html);
  };

  const ItaliandataHtmlHandle = (html) => {
    console.log(
      html,
      draftToHtml(JSON.parse(html)),
      "---------html-----------------"
    );
  
    setitalianHtmlContent(draftToHtml(JSON.parse(html)));
    setitalianHtmlEntity(html);
  };

  useEffect(() => {
    if (typeof img === "string") {
      setPrev(`${BASE_URL}${img}`);
    } else if (img instanceof Blob || img instanceof File) {
      setPrev(URL.createObjectURL(img));
    } else {
      // Handle the case when img is neither a string nor a Blob/File
      // You might want to throw an error, log a warning, or handle it in a different way based on your application's requirements
      console.error("Invalid img type:", img);
    }
  }, [img]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    const selectedImages = [];

    Array.from(files).forEach((file) => {
      if (selectedImages.length < 3 && file.type.startsWith("image/")) {
        selectedImages.push(file);
      }
    });

    setImages(selectedImages);
    setImageserr("");
  };

  useEffect(() => {
    console.log(
      viewRoute?.data?.html_entity_content,
      "viewRoute?.data?.html_entity_content"
    );
    if (viewRoute?.data?._id == id) {
      setHtmlContent(viewRoute?.data?.description);
      setitalianHtmlContent(viewRoute?.data?.italian_description);
      setGivenContent(viewRoute?.data?.description);
      setitalianGivenContent(viewRoute?.data?.italian_description)
      setImages(viewRoute?.data?.cover_image);
      setTitle(viewRoute?.data?.title);
      setItalianTitle(viewRoute?.data?.italian_title)
      setType(viewRoute?.data?.type);
      setwebdata(viewRoute?.data?.html_entity_content);
      setitalianwebdata(viewRoute?.data?.italian_html_entity_content)
      setImg(viewRoute?.data?.main_image);
      setCountryCode(viewRoute?.data?.country_code);
      setphoneNumber(viewRoute?.data?.phone_number);
      setemail(viewRoute?.data?.email == null ? "" : viewRoute?.data?.email);
      setwebsite(viewRoute?.data?.website == null ? "" : viewRoute?.data?.website);
      setRoutetype(viewRoute?.data?.near_by_routes);
    }
  }, [viewRoute]);


  useEffect(() => {

    console.log(viewRoute?.data,"length")
    // Extract country codes from viewRoute?.data?.country_code array
    if (viewRoute?.data?.country_code?.length == 0) {
      setCountryCode([
        {
          country_code: "",
        },
      ]);
    }else{
      const countryCodes = viewRoute?.data?.country_code?.map((code) => ({
        country_code: code,
      }));
      // Set the extracted country codes to the state
      setCountryCode(countryCodes);

    }
  }, [viewRoute]);

  useEffect(() => {
    if(viewRoute?.data?.phone_number?.length == 0){
      setphoneNumber([
        {
          phone_number: "",
        },
      ]);

    }else{

      const countryCodes = viewRoute?.data?.phone_number?.map((code) => ({
        phone_number: code,
      }));
      // Set the extracted country codes to the state
      setphoneNumber(countryCodes);

    }
  }, [viewRoute]);

  const handleaddcodeclone = () => {
    setCountryCode([...countryCode, { country_code: "" }]);
  };
  const handleaddphoneclone = () => {
    setphoneNumber([...phoneNumber, { phone_number: "" }]);
  };


  const handlecountrycodechange = (e, i) => {
    // const onChangeVal = [...countryCode];
    // onChangeVal[i][e.target.name] = e.target.value;
    // setCountryCode(onChangeVal);

    const updatedCountryCode = [...countryCode];
    updatedCountryCode[i] = {
      ...updatedCountryCode[i],
      country_code: e.target.value,
    };
    setCountryCode(updatedCountryCode);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };
  const handlephonechange = (e, i) => {
    // const onChangeVal = [...phoneNumber];
    // onChangeVal[i][e.target.name] = e.target.value;
    // setphoneNumber(onChangeVal);

    const updatedCountryCode = [...phoneNumber];
    updatedCountryCode[i] = {
      ...updatedCountryCode[i],
      phone_number: e.target.value,
    };
    setphoneNumber(updatedCountryCode);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };


  const handlesave = () => {
    let isvalid = true;

    if (!htmlContent) {
      if (italianhtmlContent) {
        setHtmlContenterr("");
      } else {
        setHtmlContenterr("Required*");
        isvalid = false;
      }
    }
    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }

    // if (!countryCode) {
    //   setCountryCodeerr("Required*");
    //   isvalid = false;
    // }
    // if (!phoneNumber) {
    //   setphoneNumbererr("Required*");
    //   isvalid = false;
    // }
    // if (!email) {
    //   setemailerr("Required*");
    //   isvalid = false;
    // }
    // if (!website) {
    //   setwebsiteerr("Required*");
    //   isvalid = false;
    // }
    // if (!prev) {
    //   setPreverr("Required*");
    //   isvalid = false;
    // }
    if (images.length == 0) {
      setPreverr("Required*");
      isvalid = false;
    }
    if (!routeType) {
      setRoutetypeerr("Required*");
      isvalid = false;
    }
    // if (!type) {
    //   setTypeerr("Required*");
    //   isvalid = false;
    // }

    if (isvalid) {
      const formdata = new FormData();
      formdata.append("toSeeId", id);

      countryCode.forEach((code, index) => {
        console.log(code?.country_code == "","code");
        if (code?.country_code == "") {
          formdata.append(`country_code[${index}]`, "");
        } else {
          formdata.append(`country_code[${index}]`, code?.country_code);
        }
      });

      phoneNumber.forEach((phoneNum, index) => {
        console.log(phoneNumber);
        if (phoneNum?.phone_number == "") {
          formdata.append(`phone_number[${index}]`,"");
        } else {
          formdata.append(`phone_number[${index}]`, phoneNum?.phone_number);
        }
      });

      
      // countryCode.forEach((code, index) => {
      //   console.log(code?.country_code);
      //   formdata.append(`country_code[${index}]`, code?.country_code);
      // });

      // phoneNumber.forEach((phoneNumber, index) => {
      //   console.log(phoneNumber);
      //   formdata.append(`phone_number[${index}]`, phoneNumber?.phone_number);
      // });
      formdata.append("mainImage", img);
      formdata.append("description", htmlContent);
      formdata.append("italian_description", italianhtmlContent);
      formdata.append("title", title);
      formdata.append("italian_title",italianTile)
      // formdata.append("coverImage", img);
      formdata.append("type", type);
      formdata.append("html_entity_content", htmlEntity);
      formdata.append("italian_html_entity_content", italianhtmlEntity);
      if (website) {
        formdata.append("website", website);
      }else{
        formdata.append("website", "");
      }
      if (email) {
        formdata.append("email", email);
      }else{
        formdata.append("email", "");
      }
      // if (countryCode) {
      //   formdata.append("country_code", countryCode);
      // }else{
      //   formdata.append("country_code", 0);
      // }
      // if (phoneNumber) {
      //   formdata.append("phone_number", phoneNumber);
      // }else{
      //   formdata.append("phone_number", "");
      // }

      formdata.append("near_by_routes", routeType);

      // formdata.append("html_entity_content",htmlContent)

      for (let i = 0; i < images.length; i++) {
        console.log(images[i], "loop");

        formdata.append("coverImage", images[i]);
      }

      dispatch(edittosee(formdata)).then((res) => {
        if (res?.payload?.success) {
          navigate("/ToSee");
        }
      });
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>
            {/* {location?.state?.name == "edit" ? "Edit Routes" : "Create Routes"} */}
            Edit Tosee
          </h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ToSee">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-5">
            <div className="customer-form-new">
              <div className="customer-form-inner m-0">
                <Row>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                          name="title"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            setTitleerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {titleerr}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Title (Italian):</Form.Label>
                        <Form.Control
                          name="title"
                          value={italianTile}
                          onChange={(e) => {
                            setItalianTitle(e.target.value)
                            // setTitleerr("")
                            }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {titleerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Tosee Type:</Form.Label>

                        <Form.Select
                          name="type"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setTypeerr("");
                          }}
                          aria-label="Default select example"
                        >
                          <option>Select</option>
                          <option value="0">Others</option>
                          <option value="1">Parking</option>
                          <option value="2">Bus Stop</option>
                          <option value="3">Train Stop</option>
                          <option value="4">Wifi Points</option>
                          <option value="5">Camping Area</option>
                          <option value="6">Picnic Area</option>
                          <option value="7">Panoramic Points</option>
                          <option value="8">Information Points</option>
                        </Form.Select>
                        <Form.Label className="text-danger">
                          {typeerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="admin-box-select">
                      <Form.Group className="mb-3 w-100">
                        <Form.Label>Country Code:</Form.Label>
                        {/* <Form.Control
                          // value={createRoute.title}
                          value={countryCode}
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                            setCountryCodeerr("")
                            // setTitleerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {countryCodeerr}
                        </Form.Label> */}

                        {countryCode?.map((cat, i) => {
                          return (
                            <div className="w-100">
                              <Form.Group className="mb-3 width-80 width-100 duplicate-field">
                                {/* <Form.Label>Country Code:</Form.Label> */}
                                <Form.Control
                                  name="countryCode"
                                  onChange={(e) =>
                                    handlecountrycodechange(e, i)
                                  }
                                  value={countryCode[i].country_code}
                                  type="text"
                                  placeholder="Type "
                                />
                                <Form.Label className="text-danger">
                                  {/* {countryCodeerr} */}
                                </Form.Label>
                              </Form.Group>
                            </div>
                          );
                        })}
                      </Form.Group>
                    </div>
                    
                 

                 
                    <div onClick={handleaddcodeclone} className="add-cate-icon">
                      <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 1V19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 10H19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="admin-box-select">
                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number:</Form.Label>
                          {/* <Form.Control
                          // value={createRoute.title}
                          value={phoneNumber}
                          onChange={(e) => {
                            setphoneNumber(e.target.value);
                            setphoneNumbererr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {phoneNumbererr}
                        </Form.Label> */}

                          {phoneNumber?.map((cat, i) => {
                            return (
                              <>
                                <Form.Group className="mb-3 duplicate-field width-100">
                                  {/* <Form.Label>Country Code:</Form.Label> */}
                                  <Form.Control
                                    name="phoneNumber"
                                    onChange={(e) => handlephonechange(e, i)}
                                    value={phoneNumber[i].phone_number}
                                    type="text"
                                    placeholder="Type "
                                  />
                                  <Form.Label className="text-danger">
                                    {/* {countryCodeerr} */}
                                  </Form.Label>
                                </Form.Group>
                              </>
                            );
                          })}
                        </Form.Group>
                      </div>
                    
                  

                  
                    <div
                      onClick={handleaddphoneclone}
                      className="add-cate-icon"
                    >
                      <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 1V19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 10H19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                    </div>
                  </Col>

                  
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                          // value={createRoute.title}
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                            setemailerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {emailerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Website:</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          value={website}
                          onChange={(e) => {
                            setwebsite(e.target.value);
                            setwebsiteerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {websiteerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Cover Image</Form.Label>
                      <div className="upload-cover-image">
                        <input
                          // name="coverImage"
                          // value={img}
                          onChange={handleImageChange}
                          type="file"
                          className="form-control"
                          multiple
                        />
                        <p>Upload Cover Image</p>
                        <div className="create-upload-images">
                          {" "}
                          {images.map((image, index) => {
                            return (
                              <div key={index}>
                                <img
                                  src={
                                    typeof image === "string"
                                      ? `${BASE_URL}${image}`
                                      : URL.createObjectURL(image)
                                  }
                                  alt={`Image ${index + 1}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <Form.Label className="text-danger">
                        {imageserr}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Main Image</Form.Label>
                      <div className="upload-cover-image">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            setImg(e.target.files[0]);
                            // setPrev(URL.createObjectURL(img));
                            // setPreverr("");
                          }}
                        />
                        <p>Upload Main Image</p>
                        <div className="create-upload-images">
                          <img src={prev} />
                        </div>
                      </div>
                      <Form.Label className="text-danger">{preverr}</Form.Label>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Route Type:</Form.Label>

                        <Form.Select
                          name="type"
                          value={routeType}
                          onChange={(e) => {
                            setRoutetype(e.target.value);
                            setRoutetypeerr("");
                          }}
                          aria-label="Default select example"
                        >
                          <option>Select</option>
                          {data?.data?.routes?.map((routes) => {
                            return (
                              <option value={routes?._id}>
                                {routes?.title}
                              </option>
                            );
                          })}

                          {/* <option value="1">Hostel</option>
                              <option value="2">Guest House</option>
                              <option value="3">Pilgram Hostel</option> */}
                        </Form.Select>
                        <Form.Label className="text-danger">
                          {routeTypeerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={12} className="mt-4">
                    <Form.Label>Description (English)</Form.Label>
                    <div className="rich-text-edit-main">
                      <EditTextAditor
                        htmlHandle={dataHtmlHandle}
                        givenContent={givenContent}
                        webData={webdata}
                        // seterror={seterror}
                        // desc={dsc}
                        //  handleeditdesc={handleeditdesc}
                      />
                    </div>
                    <Form.Label className="text-danger">
                      {htmlContenterr}
                    </Form.Label>
                  </Col>


                  <Col lg={12} className="mt-4">
                    <Form.Label>Description (Italian)</Form.Label>
                    <div className="rich-text-edit-main">
                      <ItalianEditTextAditor
                        htmlHandle={ItaliandataHtmlHandle}
                        givenContent={italiangivenContent}
                        webData={italianwebdata}
                        // seterror={seterror}
                        // desc={dsc}
                        //  handleeditdesc={handleeditdesc}
                      />
                    </div>
                    <Form.Label className="text-danger">
                      {/* {htmlContenterr} */}
                    </Form.Label>
                  </Col>  
                  <Col lg={2} className="mx-auto">
                    <div
                      onClick={handlesave}
                      className="cmn-btn d-flex justify-content-center mt-4"
                    >
                      {/* <Link to="#" className="common-colr-btn"> */}
                      <button className=" common-colr-btn"> SAVE</button>
                      {/* </Link> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
