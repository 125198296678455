import { createSlice } from "@reduxjs/toolkit";
import { accomodationList, listRoutes, routeProfile, toseeList, toseeprofile } from "../Action/AdminActions";
import { toast } from "react-toastify";

export const toseeSlice = createSlice({
  name: "toseeSlice",
  initialState: {
    listing: "",
    databyid:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toseeList.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.listing = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(toseeprofile.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.databyid = action.payload;
      } else {
        toast.error(action.payload.message);
      }
    });
    
  },
});

export default toseeSlice.reducer;
