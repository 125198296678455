import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Editor } from "@tinymce/tinymce-react";
import Index from "../Components/Layout/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  accomomdationProfile,
  createaccomodations,
  editaccomodation,
  listRoutes,
  listRoutesforDropdown,
} from "../Redux/Action/AdminActions";
import MyEditor from "../Components/Layout/TextEditor";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../Components/Layout/TextEditor";
import ItalianTextAditor from "../Components/Layout/ItalianTextEditor";

export default function CreateAccommodation() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [countryCode, setCountryCode] = useState([
    {
      country_code: "",
    },
  ]);
  const [phoneNumber, setphoneNumber] = useState([
    {
      phone_number: "",
    },
  ]);
  const data = useSelector((state) => state.routesdata.listroutes);
  console.log(data, "list");

  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [routeType,setRoutetype] = useState("")
  const [htmlContent, setHtmlContent] = useState();
  const [italianhtmlContent, setitalianHtmlContent] = useState();
  const [title, setTitle] = useState("");
  const [italianTile,setItalianTitle] = useState("")
  const [type, setType] = useState("");
  const [prev, setPrev] = useState(null);
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [titleerr, setTitleerr] = useState("");
  const [typeerr, setTypeerr] = useState("");
  const [preverr, setPreverr] = useState(null);
  const [htmlEntity, setHtmlEntity] = useState("");
  const [italianhtmlEntity, setItalianHtmlEntity] = useState("")
  const [imageserr, setImageserr] = useState("");
  const [img, setImg] = useState();
  // const [countryCode, setCountryCode] = useState("");
  // const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [website, setwebsite] = useState("");
  const [countryCodeerr, setCountryCodeerr] = useState("");
  const [phoneNumbererr, setphoneNumbererr] = useState("");
  const [emailerr, setemailerr] = useState("");
  const [websiteerr, setwebsiteerr] = useState("");
  const [routeTypeerr,setRoutetypeerr] = useState("")

  const handleImageChange = (e) => {

    
    const files = e.target.files;
  

    const selectedImages = [];

    Array.from(files).forEach((file) => {
     
      if (selectedImages.length < 3 && file.type.startsWith("image/")) {
        selectedImages.push(file);
      }
    });

    setImages(selectedImages);
    setImageserr("")

  };

  const dataHtmlHandle = (html) => {
    
    setHtmlEntity(html);
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlContenterr("");
  };

  const italiandataHtmlHandle = (html) => {
    console.log(html, "itlaisnhtml");

    setItalianHtmlEntity(html);
    setitalianHtmlContent(draftToHtml(JSON.parse(html)));
    // setHtmlContenterr("");
  };


  
  useEffect(() => {
    dispatch(
      listRoutesforDropdown()
    );
  }, []);


  const handleaddcodeclone = () => {
    setCountryCode([...countryCode, { country_code: "" }]);
  };
  const handleaddphoneclone = () => {
    setphoneNumber([...phoneNumber, { phone_number: "" }]);
  };

  const handlecountrycodechange = (e, i) => {
    const onChangeVal = [...countryCode];
    onChangeVal[i][e.target.name] = e.target.value;
    setCountryCode(onChangeVal);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };
  const handlephonechange = (e, i) => {
    const onChangeVal = [...phoneNumber];
    onChangeVal[i][e.target.name] = e.target.value;
    setphoneNumber(onChangeVal);

    // setCountryCerr((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };
  

  

  const handlesave = () => {
    let isvalid = true;
    
    if (!htmlContent) {
      if (italianhtmlContent) {
        setHtmlContenterr("");
      } else {
        setHtmlContenterr("Required*");
        isvalid = false;
      }
    }
    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }
    if (images.length==0) {
      setImageserr("Required*");
      isvalid = false;
    }
    if (!type) {
      setTypeerr("Required*");
      isvalid = false;
    }
    
   
    if (!prev) {
      setPreverr("Required*");
      isvalid = false;
    }
    if (!routeType) {
      setRoutetypeerr("Required*");
      isvalid = false;
    }

    if (isvalid) {
      const formdata = new FormData();

      countryCode.forEach((code, index) => {
        console.log(code?.countryCode === undefined, "code");
        if (code?.countryCode === undefined) {
          // formdata.append(`country_code[${index}]`, "");
        } else {
          formdata.append(`country_code[${index}]`, code.countryCode);
        }
      });
    
      phoneNumber.forEach((phoneNum, index) => {
        console.log(phoneNum?.phoneNumber, "phonenumber");
        if (phoneNum?.phoneNumber === undefined) {
          // formdata.append(`phone_number[${index}]`, "");

        } else {
          formdata.append(`phone_number[${index}]`, phoneNum.phoneNumber);
        }
      });

      formdata.append("description", htmlContent);
      formdata.append("italian_description", italianhtmlContent);
      formdata.append("title", title);
      formdata.append("italian_title",italianTile)
    
      formdata.append("type", type);
      formdata.append("html_entity_content", htmlEntity);
      formdata.append("italian_html_entity_content", italianhtmlEntity);
      formdata.append("mainImage",img)
     if (website) {
        formdata.append("website", website);
      }else{
        formdata.append("website", "");
      }
      if (email) {
        formdata.append("email", email);
      }else{
        formdata.append("email", "");
      }
     
      formdata.append("near_by_routes", routeType);


      for (let i = 0; i < images.length; i++) {
        console.log(images[i], "loop");

        formdata.append("coverImage", images[i]);
      }

      dispatch(createaccomodations(formdata)).then((res) => {
        if (res?.payload?.success) {
          navigate("/Accommodation");
        }
      });
    }
  };




  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2> Create Accomodations</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Accommodation">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center "
            ></Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8} className="mb-5">
            <div className="customer-form-new">
              <div className="customer-form-inner m-0">
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <div className="admin-box-select">
                          <Form.Group className="mb-3">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control
                              name="title"
                              // value={createRoute.title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleerr("");
                              }}
                              type="text"
                              placeholder="Type......."
                            />
                            <Form.Label className="text-danger">
                              {titleerr}
                            </Form.Label>
                          </Form.Group>

                          <Form.Group className="mb-3">
                        <Form.Label>Title (Italian):</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          onChange={(e) => {
                            setItalianTitle(e.target.value)
                            // setTitleerr("")
                            }}
                          type="text"
                          placeholder="Type......."
                        />
                        {/* <Form.Label className="text-danger">
                          {titleerr}
                        </Form.Label> */}
                      </Form.Group>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="admin-box-select">
                          <Form.Group className="mb-3">
                            <Form.Label>Accommodation Type:</Form.Label>

                            <Form.Select
                              name="type"
                              // value={createRoute.type}
                              onChange={(e) => {
                                setType(e.target.value);
                                setTypeerr("");
                              }}
                              aria-label="Default select example"
                            >
                              <option>Select</option>
                              <option value="0">Hotel</option>
                              <option value="1">Hostel</option>
                              <option value="2">Guest House</option>
                              <option value="3">Pilgram Hostel</option>
                            </Form.Select>
                            <Form.Label className="text-danger">
                            {typeerr}
                            </Form.Label>
                          </Form.Group>
                        </div>
                      </Col>






                      
                      <Col lg={6}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="admin-box-select">
                      <Form.Group className="mb-3 w-100">
                        <Form.Label>Country Code:</Form.Label>
                        {/* <Form.Control
                          // value={createRoute.title}   
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                            setCountryCodeerr("");
                            // setTitleerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        /> */}

                        {countryCode?.map((cat, i) => {
                          return (
                            <div className="w-100">
                              <Form.Group className="mb-3 width-100 duplicate-field">
                                {/* <Form.Label>Country Code:</Form.Label> */}
                                <Form.Control
                                  name="countryCode"
                                  onChange={(e) =>
                                    handlecountrycodechange(e, i)
                                  }
                                  value={countryCode.country_code}
                                  type="text"
                                  placeholder="Type "
                                />
                                <Form.Label className="text-danger">
                                  {/* {countryCodeerr} */}
                                </Form.Label>
                              </Form.Group>
                            </div>
                          );
                        })}

                        {/* <Form.Label className="text-danger">
                          {countryCodeerr}
                        </Form.Label> */}
                      </Form.Group>
                    </div>
                    <div onClick={handleaddcodeclone} className="add-cate-icon">
                      <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 1V19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 10H19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                    </div>
                  </Col>
                 
                  <Col lg={6}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="admin-box-select">
                      <Form.Group className="mb-3 ">
                        <Form.Label>Phone Number:</Form.Label>
                        {/* <Form.Control
                          // value={createRoute.title}
                          onChange={(e) => {
                            setphoneNumber(e.target.value);
                            setphoneNumbererr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        /> */}
                        {/* <Form.Label className="text-danger">
                          {phoneNumbererr}
                        </Form.Label> */}

                        {phoneNumber?.map((cat, i) => {
                          return (
                            <>
                              <Form.Group className="mb-3 duplicate-field width-100">
                                {/* <Form.Label>Country Code:</Form.Label> */}
                                <Form.Control
                                  name="phoneNumber"
                                  onChange={(e) =>
                                    handlephonechange(e, i)
                                  }
                                  value={phoneNumber.phone_number}
                                  type="text"
                                  placeholder="Type "
                                />
                                <Form.Label className="text-danger">
                                  {/* {countryCodeerr} */}
                                </Form.Label>
                              </Form.Group>
                            </>
                          );
                        })}



                      </Form.Group>
                    </div>
                    <div onClick={handleaddphoneclone} className="add-cate-icon">
                      <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 1V19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 10H19"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                    </div>
                  </Col>



                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                          // value={createRoute.title}
                          onChange={(e) => {
                            setemail(e.target.value);
                            setemailerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {emailerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="admin-box-select">
                      <Form.Group className="mb-3">
                        <Form.Label>Website:</Form.Label>
                        <Form.Control
                          name="title"
                          // value={createRoute.title}
                          onChange={(e) => {
                            setwebsite(e.target.value);
                            setwebsiteerr("");
                          }}
                          type="text"
                          placeholder="Type......."
                        />
                        <Form.Label className="text-danger">
                          {websiteerr}
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Col>





                  
                    </Row>
                    <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Cover Image</Form.Label>

                      <div className="upload-cover-image">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageChange}
                        />
                        <p>Upload Cover Image</p>
                        <div className="create-upload-images">
                          {" "}
                          {images.map((image, index) => {
                            console.log(image, "ijiin");
                            return (
                              <div key={index}>
                                <img
                                  src={
                                    typeof image === "string"
                                      ? `${BASE_URL}${image}`
                                      : URL.createObjectURL(image)
                                  }
                                  alt={`Image ${index + 1}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <Form.Label className="text-danger">
                        {imageserr}
                      </Form.Label>
                    </Form.Group>
                    </Col>
                  
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Main Image</Form.Label>
                      <div className="upload-cover-image">
                        <input
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            setImg(e.target.files[0]);
                            setPrev(URL.createObjectURL(e.target.files[0]));
                            setPreverr("")
                          }}
                        />
                        <p>Upload Main Image</p>
                        <div className="create-upload-images"><img src={prev}/></div>
                      </div>
                      <Form.Label className="text-danger">
                      {preverr}

                      </Form.Label>
                    </Form.Group>
                  </Col>
                  </Col>


                  <Col lg={6}>
                        <div className="admin-box-select">
                          <Form.Group className="mb-3">
                            <Form.Label>Route Type:</Form.Label>

                            <Form.Select
                              name="type"
                              // value={createRoute.type}
                              onChange={(e) => {
                                setRoutetype(e.target.value);
                                setRoutetypeerr("");
                              }}
                              aria-label="Default select example"
                            >
                              <option>Select</option>
                              {data?.data?.routes?.map((routes)=>{
                                return (
                                  <option value={routes?._id}>{routes?.title}</option>
                                )
                              })}
                              
                              {/* <option value="1">Hostel</option>
                              <option value="2">Guest House</option>
                              <option value="3">Pilgram Hostel</option> */}
                            </Form.Select>
                            <Form.Label className="text-danger">
                            {routeTypeerr}
                            </Form.Label>
                          </Form.Group>
                        </div>
                      </Col>






                      <Col lg={12} className="mt-4">
                    <Form.Label>Description (English)</Form.Label>
                    <div className="rich-text-edit-main">
                      <TextEditor htmltext={dataHtmlHandle} />
                    </div>
                    <Form.Label className="text-danger">
                      {htmlContenterr}
                    </Form.Label>
                  </Col>

                  <Col lg={12} className="mt-4">
                    <Form.Label>Description (Italian)</Form.Label>
                    <div className="rich-text-edit-main">
                      <ItalianTextAditor htmltext={italiandataHtmlHandle} />
                    </div>
                    <Form.Label className="text-danger">
                      {/* {htmlContenterr} */}
                    </Form.Label>
                  </Col>

                  <Col lg={12}>
                    <div
                      onClick={handlesave}
                      className="cmn-btn d-flex justify-content-center mt-4 "
                    >
                      {/* <Link to="#" className="common-colr-btn"> */}
                      <button className=" common-colr-btn"> SAVE</button>
                      {/* </Link> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}