import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom"
import { toast } from "react-toastify";
import { ChangeAdminPassword } from "../Redux/Action/AdminActions";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [Password, setPassword] = useState({
    currentPassword: "",
    password: "",
  });
  const [cperror, setCPerror] = useState("");
  const [error, setError] = useState({
    currentPassworderr: "",
    passworderr: "",
  });

  const handlechange = (e) => {
    setPassword((old) => ({ ...old, [e.target.name]: e.target.value }));

    setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handleclick = (e) => {
    e.preventDefault();
    validation();
  };
  const handleconfirmPassword = (e) => {
    setconfirmPassword(e.target.value);
    setCPerror("");
  };

  const validation = async () => {
    let isvalid = true;
    let err = {
      currentPassworderr: "",
      passworderr: "",
    };

    if (!Password.currentPassword) {
      err.currentPassworderr = "Required*";
      isvalid = false;
    }

    if (!Password.password) {
      err.passworderr = "Required*";
      isvalid = false;
    }

    if (!confirmPassword) {
      setCPerror("Required*");
      isvalid = false;
    }

    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      console.log(Password);
      console.log(confirmPassword);
      if (Password?.password === confirmPassword) {
        dispatch(ChangeAdminPassword(Password)).then(res=>{
          if(res?.payload?.success){
            toast.success(res?.payload?.message)
            navigate("/Dashboard")
          }else{
            toast.error("Old Password is Incorrect")
          }
        })
        
        // setconfirmPassword(" ")
        
      }else{
        setCPerror("Password did not match")
      }
    }
  };

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>

                <Form className="change-password-form px-5">
                  <Row className="">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label style={{ color: "black" }}>
                        Old Password
                      </Form.Label>
                      <Form.Control
                        value={Password.currentPassword}
                        name="currentPassword"
                        onChange={handlechange}
                        type="password"
                        placeholder="Old Password"
                      />
                      <Form.Label className="text-danger">
                        {error?.currentPassworderr}
                      </Form.Label>
                    </Form.Group>
                  </Row>
                  <Row className="">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label style={{ color: "black" }}>
                        New Password
                      </Form.Label>
                      <Form.Control
                        value={Password.password}
                        name="password"
                        onChange={handlechange}
                        type="password"
                        placeholder="New Password"
                      />
                      <Form.Label className="text-danger">
                        {error?.passworderr}
                      </Form.Label>
                    </Form.Group>
                  </Row>
                  <Row className="">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label style={{ color: "black" }}>
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        // value={confirmPassword}
                        name="confirmPassword"
                        onChange={handleconfirmPassword}
                        type="password"
                        placeholder="Confirm Password"
                      />
                      <Form.Label className="text-danger">{cperror}</Form.Label>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button onClick={handleclick} type="button">
                        SAVE
                      </button>
                    </div>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
